import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import partnersSubmitMutation from '../gql/partners.gql';

@Injectable()
export class PartnersGQLService {
  constructor(private apollo: Apollo) {}

  public partnersSubmit(
    languageCode: string,
    firstName: string,
    lastName: string,
    email: string,
    contact: string,
    discountCode: string,
    userOrigin: string,
    province: string,
  ): Observable<FetchResult<any>> {
    return this.apollo.mutate<any>({
      mutation: partnersSubmitMutation,
      variables: {
        languageCode,
        firstName,
        lastName,
        email,
        contact,
        discountCode,
        userOrigin,
        province,
      },
      errorPolicy: 'all',
    })
  }
}
