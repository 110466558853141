import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { PROD_AFFILIATE_COOKIE, DEV_AFFILIATE_COOKIE } from './constants/cookieName';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AppCookieService {
  constructor(private cookieService: CookieService) {}

  public getCookie(cookieName: string) {
    const cookies = this.cookieService.get(cookieName);
    if (!cookies) return [];

    return cookies;
  }

  public setCookie(cookieName: string, cookieValue: string, cookieExpires: Date) {
    this.cookieService.set(cookieName, cookieValue, cookieExpires, '/');
  }

  public setAffiliatesCookie(cookieValue: string) {
    const AFFILIATE_COOKIE = environment.production ? PROD_AFFILIATE_COOKIE : DEV_AFFILIATE_COOKIE;
    this.cookieService.set(AFFILIATE_COOKIE, cookieValue, 1, '/', '.lifeafterme.com');
  }
}
