import { NgModule } from '@angular/core';
import { FaqService } from './services/faq.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [FaqService],
})
export class FaqModule {}
