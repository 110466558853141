<div class="footer-socials">
  <h2 i18n class="footer-socials__connect">Connect</h2>
  <div class="footer-socials__icons">
    <a href="https://www.facebook.com/lifeaftermesocial" target="_blank">
      <img src="assets/images/social-icons/facebook.webp" width="43" height="43" alt="facebook" />
    </a>
    <a href="https://www.linkedin.com/company/lifeafterme" target="_blank">
      <img src="assets/images/social-icons/linkedin.webp" width="43" height="43" alt="linkedin" />
    </a>
    <a href="https://www.youtube.com/channel/UCT9qW5NMVre2X0Svv0OhLJA" target="_blank">
      <img src="assets/images/social-icons/youtube.webp" width="43" height="43" alt="youtube" />
    </a>
    <a href="https://www.instagram.com/lifeaftermesocial/" target="_blank">
      <img src="assets/images/social-icons/instagram.webp" width="43" height="43" alt="instagram" />
    </a>
  </div>
</div>
