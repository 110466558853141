import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-details',
  templateUrl: './footer-details.component.html',
  styleUrls: ['./footer-details.component.scss'],
})
export class FooterDetailsComponent implements OnInit {
  public LamNumber = '31627263842';
  public LamNumberDisplay = '+31 627 263 842';
  public LamEmail = `${environment.supportEmail}`;
  public LamAddress = `Life After Me B.V. Nederland <br /> Heerlen <br /> Silhof 19`;
  public isCaVersion = false;

  ngOnInit(): void {
    this.isCaVersion = environment.region === 'ca';
    if (this.isCaVersion) {
      this.LamNumber= '5198043111';
      this.LamNumberDisplay = '+519-804-3111';
      this.LamAddress = `745 Bridge Street West,  <br /> Unit #7,<br />  Waterloo ON N2V 2G6`
    }
  }

}
