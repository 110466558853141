import { Component, Input, OnInit } from '@angular/core';
import { WindowService } from 'src/app/features/core/services/window.service';
import { MenuItem } from '../../interfaces/MenuItem';
import { AFFILIATES } from '../../utils/affiliates';

@Component({
  selector: 'app-front-pages-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() public mainMenuItems: MenuItem[] = [];

  @Input() public secondaryMenuItems: MenuItem[] = [];

  constructor(private windowService: WindowService) {}

  // render different background color for footer only on new landing page
  public showLandingFooter: boolean = false;

  ngOnInit(): void {
    const affiliates = AFFILIATES;
    const currentUrl = this.windowService.window?.location.pathname
    const affiliate = affiliates.find((a) => currentUrl?.includes(a.path));

    if (affiliate || currentUrl?.includes('/ifa')) {
      this.showLandingFooter = true;
    }
  }
}
