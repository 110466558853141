<div class="lam-button">
  <button
    *ngIf="buttonType === buttonTypes.SIMPLE_BUTTON"
    mat-raised-button
    (click)="onButtonClick()"
    [ngStyle]="{ 'background-color': backgroundColor, 'color': color }"
    [innerHtml]="buttonText"
  ></button>
  <a
    *ngIf="buttonType === buttonTypes.INTERNAL_LINK_RELATIVE"
    [routerLink]="link"
    [fragment]="fragment"
    mat-raised-button
    [ngStyle]="{ 'background-color': backgroundColor, 'color': color }"
    [innerHtml]="buttonText"
  ></a>
  <a
    *ngIf="buttonType === buttonTypes.INTERNAL_LINK_ABSOLUTE"
    [href]="link"
    mat-raised-button
    [ngStyle]="{ 'background-color': backgroundColor, 'color': color }"
    [innerHtml]="buttonText"
  ></a>
  <a
    *ngIf="buttonType === buttonTypes.EXTERNAL_LINK"
    [href]="link"
    mat-raised-button
    [ngStyle]="{ 'background-color': backgroundColor, 'color': color }"
    target="_blank"
    [innerHtml]="buttonText"
  ></a>
</div>
