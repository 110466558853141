import { NgModule } from '@angular/core';
import { SharedModule } from '../../features/shared/shared.module';
import { PartnersPagesComponent } from './partners-pages.component';
import { PartnersPagesRoutingModule } from './partners-pages.routing';
import { PartnersFooterPagesComponent } from './components/footer/partners-footer.component';
import { PartnersHeroSectionPagesComponent } from './components/hero-section/partners-hero-section.component';
import { PartnersHeaderComponent } from './components/header/partners-header.component';
import { PartnersFormComponent } from './components/partners-form/partners-form.component';
import { PartnersFormFacade } from './components/partners-form/partners-form.facade';
import { PartnersGQLService } from './components/partners-form/services/partners.gql.service';
import { PartnersFormService } from './components/partners-form/services/partners-form.service';
import { DiscountGQLService } from './components/partners-form/services/discount.gql.service';

@NgModule({
  declarations: [
    PartnersPagesComponent,
    PartnersFooterPagesComponent,
    PartnersHeroSectionPagesComponent,
    PartnersHeaderComponent,
    PartnersFormComponent,
  ],
  imports: [SharedModule, PartnersPagesRoutingModule],
  exports: [
    PartnersFooterPagesComponent,
    PartnersHeroSectionPagesComponent,
    PartnersHeaderComponent,
    PartnersFormComponent,
  ],
  providers: [PartnersFormFacade, PartnersGQLService, PartnersFormService, DiscountGQLService],
})
export class PartnersPagesModule {}
