import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyImgDirective } from 'src/app/features/shared/directives/lazy-image.directive';
import { MaterialModule } from '../material/material.module';
import { HeroSectionComponent } from './containers/hero-section/hero-section.component';
import { IntroSectionComponent } from './components/intro-section/intro-section.component';
import { LAMButtonComponent } from './components/lam-button/lam-button';
import { LogoComponent } from './components/logo/logo.component';
import { SectionMaskComponent } from './components/section-mask/section-mask.component';
import { InfoComponent } from './components/info-section/info-section.component';
import { HowItWorksSectionComponent } from './components/how-it-works-section/how-it-works-section.component';
import { TestimonialSectionComponent } from './containers/testimonial-section/testimonial-section.component';
import { FeaturesSectionComponent } from './components/features-section/features-section.component';
import { StartFreeSectionComponent } from './components/start-free-section/start-free-section.component';
import { SecuritySectionComponent } from './components/security-section/security-section.component';
import { NewsletterSectionComponent } from './containers/newsletter-section/newsletter-section.component';
import { EmailCTAComponent } from './components/email-cta/email-cta.component';
import { FaqCarouselComponent } from './containers/faq-carousel/faq-carousel.component';
import { PlansPricesComponent } from './components/plans-prices/plans-prices.component';
import { PlanComponent } from './components/plan/plan.component';
import { FeatureComponent } from './components/feature/feature.component';
import { FaqCarouselFacade } from './containers/faq-carousel/faq-carousel.facade';
import { NewsletterGQLService } from './containers/newsletter-section/services/newsletter.gql.service';
import { NewsletterSectionFacade } from './containers/newsletter-section/newsletter-section.facade';
import { LanguageModule } from '../language/language.module';
import { CategoriesSectionComponent } from './containers/categories-section/categories-section.component';
import { ImageSectionComponent } from './containers/image-section/image-section.component';
import { TestimonialCarouselComponent } from './containers/testimonial-carousel/testimonial-carousel.component';
import { HeroComponent } from './components/hero-landing/hero.component';
import { TestimonialComponent } from './containers/testimonial-carousel/components/testimonial/testimonial.component';
import { HowLamWorksSectionComponent } from './containers/how-LAM-works-section/how-LAM-works-section.component';
import { SingUpSectionComponent } from './containers/sign-up-section/sign-up-section.component';
import { SignUpFormService } from './containers/sign-up-section/services/signup-form.service';
import { SignUpGQLService } from './containers/sign-up-section/services/signup.gql.service';
import { SignUpSectionFacade } from './containers/sign-up-section/sign-up-section.facade';
import { HowLamWorks2SectionComponent } from './containers/how-LAM-works-2-section/how-LAM-works-2-section.component';
import { BusinessSectionFacade } from './containers/business-form-section/business-form-section.facade';
import { BusinessFormSectionComponent } from './containers/business-form-section/business-form-section.component';
import { BusinessGQLService } from './containers/business-form-section/services/business.gql.service';
import { BusinessFormService } from './containers/business-form-section/services/business-form.service';
import { IFAFormalComponent } from './templates/ifa-formal/ifa-formal.component';
import { JoinNowSectionComponent } from './components/join-now-section/join-now-section.component';
import { IFAInformalComponent } from './templates/ifa-informal/ifa-informal.component';
import { VideoComponent } from './components/video/video.component';
import { HowItWorksService } from './components/how-it-works-section/services/how-it-works.service';
import { HowItWorks2Service } from './containers/how-LAM-works-2-section/services/how-it-works-2.service';

@NgModule({
  declarations: [
    // Components
    LogoComponent,
    LAMButtonComponent,
    HeroSectionComponent,
    SectionMaskComponent,
    IntroSectionComponent,
    InfoComponent,
    HowItWorksSectionComponent,
    TestimonialSectionComponent,
    FeaturesSectionComponent,
    StartFreeSectionComponent,
    SecuritySectionComponent,
    EmailCTAComponent,
    NewsletterSectionComponent,
    FaqCarouselComponent,
    PlansPricesComponent,
    PlanComponent,
    FeatureComponent,
    CategoriesSectionComponent,
    ImageSectionComponent,
    HeroComponent,
    TestimonialCarouselComponent,
    TestimonialComponent,
    HowLamWorksSectionComponent,
    SingUpSectionComponent,
    HowLamWorks2SectionComponent,
    BusinessFormSectionComponent,
    JoinNowSectionComponent,
    VideoComponent,

    IFAFormalComponent,
    IFAInformalComponent,
    // Directives
    LazyImgDirective,
  ],
  imports: [
    RouterModule,
    MaterialModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    CommonModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    LanguageModule,
  ],
  exports: [
    // Components
    LogoComponent,
    LAMButtonComponent,
    HeroSectionComponent,
    SectionMaskComponent,
    IntroSectionComponent,
    InfoComponent,
    CarouselModule,
    HowItWorksSectionComponent,
    TestimonialSectionComponent,
    FeaturesSectionComponent,
    StartFreeSectionComponent,
    SecuritySectionComponent,
    EmailCTAComponent,
    NewsletterSectionComponent,
    FaqCarouselComponent,
    PlansPricesComponent,
    PlanComponent,
    FeatureComponent,
    CategoriesSectionComponent,
    ImageSectionComponent,
    HeroComponent,
    TestimonialCarouselComponent,
    TestimonialComponent,
    HowLamWorksSectionComponent,
    SingUpSectionComponent,
    HowLamWorks2SectionComponent,
    BusinessFormSectionComponent,
    JoinNowSectionComponent,
    VideoComponent,

    IFAFormalComponent,
    IFAInformalComponent,
    // Directives
    LazyImgDirective,

    // Modules
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LanguageModule,
  ],
  providers: [
    FaqCarouselFacade,
    NewsletterGQLService,
    NewsletterSectionFacade,
    SignUpFormService,
    SignUpGQLService,
    SignUpSectionFacade,
    BusinessGQLService,
    BusinessSectionFacade,
    BusinessFormService,
    HowItWorksService,
    HowItWorks2Service,
  ],
})
export class SharedModule {}
