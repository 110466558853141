import { Injectable } from '@angular/core';
import { DocumentService } from './document.service';

@Injectable()
export class ScriptService {
  constructor(private documentService: DocumentService) {}

  public appendScriptToHead(script: Node): void {
    if (!this.documentService.document) return;

    const { head } = this.documentService.document;
    head.appendChild(script);
  }

  public prependScriptToBody(script: Node): void {
    if (!this.documentService.document) return;

    const { body } = this.documentService.document;
    body.prepend(script);
  }
}
