<div class="header">
  <a routerLink="/">
    <app-shared-logo></app-shared-logo>
  </a>

  <ng-container *ngIf="logo">
    <img class="header__partners-logo" [src]="logo" />
  </ng-container>
  <ng-container *ngIf="!logo">
    <!-- MOBILE -->
    <div class="header--xs" *ngIf="isMobileView">
      <app-front-pages-burger-icon (iconClick)="onIconClick()"></app-front-pages-burger-icon>
    </div>

    <!-- DESKTOP -->
    <div class="header--sm" *ngIf="!isMobileView">
      <app-front-pages-main-menu
        [isMobileView]="isMobileView"
        (closeMenu)="onCloseMenu()"
      ></app-front-pages-main-menu>
    </div>
  </ng-container>
</div>
