<div class="logo">
  <img
    *ngIf="variant === 'default'"
    src="{{ logoImage }}"
    width="250"
    height="65"
    alt="life after me logo"
  />
  <img
    *ngIf="variant === 'white'"
    src="assets/images/logo/logo--white.webp"
    width="236"
    height="64"
    alt="life after me logo"
  />
</div>
