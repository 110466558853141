import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SnackbarService } from './services/snackbar.service';

@NgModule({
  declarations: [],
  imports: [SharedModule],
  exports: [],
  providers: [SnackbarService],
})
export class SnackbarModule {}
