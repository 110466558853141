import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LanguageFacade } from '../../language/language.facade';

@Injectable()
export class UrlService {
  constructor(private readonly languageFacade: LanguageFacade) {}

  public getAppUri(): string {
    const { region } = environment;
    const locale = this.languageFacade.getCurrentLocale();
    switch (region) {
      case 'ca':
        return environment.proAppUri;
      case 'nl':
        return environment.proAppUri;
      default:
        return `${environment.proAppUri}/${locale}`;
    }
  }
}
