import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { INewsletterRegisterMutation } from './interfaces/GQL';
import { NewsletterGQLService } from './services/newsletter.gql.service';

@Injectable()
export class NewsletterSectionFacade {
  constructor(private newsletterGQLService: NewsletterGQLService) {}

  public newsletterRegister(email: string): Observable<FetchResult<INewsletterRegisterMutation>> {
    return this.newsletterGQLService.newsletterRegister(email);
  }
}
