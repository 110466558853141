import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import businessSubmitMutation from '../gql/business.gql';

@Injectable()
export class BusinessGQLService {
  constructor(private apollo: Apollo) {}

  public businessSubmit(
    name: string,
    email: string,
    organization: string,
    telephone: string,
    message: string,
  ): Observable<FetchResult<any>> {
    return this.apollo.mutate<any>({
      mutation: businessSubmitMutation,
      variables: {
        email,
        name,
        telephone,    
        organization,  
        message,
      },
      errorPolicy: 'all',
    });
  }
}
