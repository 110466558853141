import { Injectable } from '@angular/core';
import {
  howItWorksTexts,
  howItWorksTextsIfa,
  howItWorksTextsFormal,
  howItWorksTextsInformal,
  howItWorksTextsSoteria,
  howItWorksTextsDhfm,
  howItWorksTextsEnvida,
  howItWorksTextsHorizons,
} from '../utils/howItWorksTexts';

@Injectable()
export class HowItWorks2Service {
  public getHowItWorksText(ifaName: string) {
    if (!ifaName) {
      return howItWorksTexts;
    }
    switch (ifaName) {
      case 'ifa':
        return howItWorksTextsIfa;
      case 'fides':
        return howItWorksTextsFormal;
      case 'leussink':
        return howItWorksTextsFormal;
      case 'soteria':
        return howItWorksTextsSoteria;
      case 'dhfm':
      case 'romilly':
        return howItWorksTextsDhfm;
      case 'envida':
        return howItWorksTextsEnvida;
      case 'horizons':
        return howItWorksTextsHorizons;
      default:
        return howItWorksTextsInformal;
    }
  }
}
