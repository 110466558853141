import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-front-pages-burger-icon',
  templateUrl: './burger-icon.component.html',
  styleUrls: ['./burger-icon.component.scss'],
})
export class BurgerIconComponent {
  @Output() public iconClick = new EventEmitter<void>();

  public onIconClick(): void {
    this.iconClick.emit();
  }
}
