import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import newsletterSubmitMutation from '../gql/newsletter-register.gql';
import { INewsletterRegisterMutation } from '../interfaces/GQL';

@Injectable()
export class NewsletterGQLService {
  constructor(private apollo: Apollo) {}

  public newsletterRegister(email: string): Observable<FetchResult<INewsletterRegisterMutation>> {
    return this.apollo.mutate<INewsletterRegisterMutation>({
      mutation: newsletterSubmitMutation,
      variables: {
        email,
      },
      errorPolicy: 'all',
    });
  }
}
