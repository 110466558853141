import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILocale } from './interfaces/ILocale';
import { LanguageState } from './state/language.state';

@Injectable({
  providedIn: 'root',
})
export class LanguageFacade {
  constructor(private languageState: LanguageState) {}

  public getAvailableLanguages(): ILocale[] {
    return this.languageState.getAvailableLanguages();
  }

  public initLocale(fallback?: string): void {
    this.languageState.initLocale(fallback);
  }

  public setCurrentLocale(locale: string): void {
    this.languageState.setCurrentLocale(locale);
  }

  public getCurrentLocale$(): Observable<string> {
    return this.languageState.getCurrentLocale$();
  }

  public getCurrentLocale(): string {
    return this.languageState.getCurrentLocale();
  }
}
