<div class="close-icon" (click)="onCloseMenu()" *ngIf="isMobileView">
  <mat-icon>close</mat-icon>
</div>
<ul class="main-menu">
  <li *ngIf="isShowThuiswinkel">
    <div class="tww_popup_wrapper_NTU1Ni0z"></div>
  </li>
  <li class="navigation__item">
    <a
      i18n
      routerLink="/why-life-after-me"
      routerLinkActive="navigation__item--active"
      color="primary"
      >Why Life After Me?</a
    >
  </li>
  <li class="navigation__item">
    <a i18n routerLink="/how-it-works" routerLinkActive="navigation__item--active"
      >How does it work?</a
    >
  </li>
  <li class="navigation__item">
    <a i18n routerLink="/security" routerLinkActive="navigation__item--active">Security</a>
  </li>
  <li class="navigation__item">
    <a i18n routerLink="/contact" routerLinkActive="navigation__item--active">Contact</a>
  </li>
  <li class="navigation__item" *ngIf="isMobileView && isGlobalVersion">
    <app-language></app-language>
  </li>
  <li class="navigation__item login" *ngIf="isMobileView">
    <a i18n [href]="appUri + '/login'">Login</a>
  </li>
  <li class="navigation__item try-for-free" *ngIf="isMobileView">
    <a i18n routerLink="/why-life-after-me">Try for free</a>
  </li>
</ul>
