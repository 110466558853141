import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class LocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  public setItem(key: string, value: string): void {
    if (!isPlatformBrowser(this.platformId)) return;

    localStorage.setItem(key, value);
  }

  public getItem(key: string): string | null {
    if (!isPlatformBrowser(this.platformId)) return null;

    return localStorage.getItem(key);
  }

  public clear(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    localStorage.clear();
  }

  public remove(key: string): void {
    if (!isPlatformBrowser(this.platformId)) return;

    localStorage.removeItem(key);
  }
}
