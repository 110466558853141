<div
  class="section-mask"
  [ngStyle]="{
    'background-image': 'url(' + maskUrl + ')'
  }"
>
  <div class="section-mask__cta" *ngIf="showButton">
    <app-shared-lam-button
      i18n-buttonText
      [buttonText]="buttonText"
      [link]="link"
      [buttonType]="buttonType"
      [fragment]="fragment"
      [backgroundColor]="customSecondaryColor"
      color="#fff"
    ></app-shared-lam-button>
  </div>
</div>
