import { Injectable } from '@angular/core';
import {
  howItWorksTexts,
  howItWorksTextsDhfm,
  howItWorksTextsFormal,
  howItWorksTextsInformal,
} from '../utils/howItWorksTexts';

@Injectable()
export class HowItWorksService {
  public getHowItWorksText(ifaName: string) {
    if (!ifaName) {
      return howItWorksTexts;
    }
    switch (ifaName) {
      case 'dhfm':
        return howItWorksTextsDhfm;
      case 'envida':
        return howItWorksTextsFormal;
      case 'fides':
        return howItWorksTextsFormal;
      case 'leussink':
        return howItWorksTextsFormal;
      case 'romilly':
        return howItWorksTextsFormal;
      case 'soteria':
        return howItWorksTextsFormal;
      default:
        return howItWorksTextsInformal;
    }
  }
}
