import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageFacade } from 'src/app/features/language/language.facade';
import { environment } from 'src/environments/environment';
import { LinkType, MenuItem } from './interfaces/MenuItem';
import {
  MAIN_MENU_ITEMS,
  SECONDARY_MENU_ITEMS,
  MAIN_MENU_ITEMS_FH,
  SECONDARY_MENU_ITEMS_FH,
} from './utils/menus';
import { AFFILIATES } from './utils/affiliates';
import { UrlService } from 'src/app/features/core/services/url.service';

@Component({
  selector: 'app-front-pages',
  templateUrl: './front-pages.component.html',
  styleUrls: ['./front-pages.component.scss'],
})
export class FrontPagesComponent implements OnInit, OnDestroy {
  public headerLogo?: string;

  public isMainMenuOpen = false;

  public isMobileView = true;

  public mainMenu: MenuItem[] = [];

  public secondaryMenu: MenuItem[] = [];

  public appUri = '';

  private breakpointsSubscription: Subscription | null = null;

  private routerSubscription: Subscription | null = null;

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    private readonly languageFacade: LanguageFacade,
    private urlService: UrlService,
  ) {}

  public ngOnInit(): void {
    this.initBreakpoints();
    this.initRouterSubscription();
    this.initMenus();
    this.filterElementsDisplayed();
    this.appUri = this.urlService.getAppUri();
  }

  public ngOnDestroy(): void {
    this.breakpointsSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  public toggleMainMenu(value?: boolean): void {
    if (value === undefined) {
      this.isMainMenuOpen = !this.isMainMenuOpen;
    } else {
      this.isMainMenuOpen = value;
    }
  }

  private initBreakpoints(): void {
    this.breakpointsSubscription = this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.Medium])
      .subscribe((result: BreakpointState) => {
        if (
          result.breakpoints[Breakpoints.Small] ||
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Medium]
        ) {
          this.isMobileView = true;
        } else {
          this.isMobileView = false;
          this.isMainMenuOpen = false;
        }
      });
  }

  private initRouterSubscription(): void {
    this.isMainMenuOpen = false;
    this.setupHeaderLogo();

    this.routerSubscription = this.router.events.subscribe(() => {
      this.isMainMenuOpen = false;
      this.setupHeaderLogo();
    });
  }

  private filterElementsDisplayed(): void {
    const locale = this.languageFacade.getCurrentLocale();
    if (locale.toLocaleLowerCase() !== 'nl') {
      this.secondaryMenu = this.secondaryMenu.filter((element) => element.text !== 'Herroepping');
    }
  }

  private initMenus(): void {
    const currentUrl = this.router.url;
    if (currentUrl.includes('horizons')) {
      this.mainMenu = [...MAIN_MENU_ITEMS_FH];
      this.secondaryMenu = [...SECONDARY_MENU_ITEMS_FH];
    } else {
      this.mainMenu = [...MAIN_MENU_ITEMS];
      this.secondaryMenu = [...SECONDARY_MENU_ITEMS];
    }

    const locale = this.languageFacade.getCurrentLocale();
    if (locale.toLocaleLowerCase() === 'nl') {
      this.secondaryMenu = SECONDARY_MENU_ITEMS.map((m) => {
        switch (m.routerLink) {
          case '/privacy-policy':
            return {
              ...m,
              routerLink: '/assets/files/privacy-policy-nl.pdf',
              type: LinkType.FILE,
            };
          case '/terms-and-conditions':
            return {
              ...m,
              routerLink: '/assets/files/terms-and-conditions-nl.pdf',
              type: LinkType.FILE,
            };
          default:
            return m;
        }
      });
    } else {
      this.secondaryMenu = this.secondaryMenu.filter(
        (m) => m.routerLink !== '/assets/files/modelformulier_voor_herroeping.pdf',
      );
    }
  }

  private setupHeaderLogo(): void {
    const affiliates = AFFILIATES;
    const currentUrl = this.router.url;
    const affiliate = affiliates.find((a) => currentUrl.includes(a.path));
    if (affiliate) {
      this.headerLogo = affiliate.logo;
    } else {
      this.headerLogo = '';
    }
  }
}
