import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DocumentService } from '../core/services/document.service';
import { ScriptService } from '../core/services/script.service';

@Component({
  selector: 'app-gtm',
  template: '',
})
export class GoogleTagManagerComponent implements OnInit {
  private gtm = 'GTM-M7K8R45';

  constructor(private scriptService: ScriptService, private documentService: DocumentService) {}

  public ngOnInit(): void {
    if (!environment.allowGTM) return;

    this.appendHeadScript();
    this.appendBodyScript();
  }

  private appendHeadScript(): void {
    if (!this.documentService.document) return;

    const script = this.documentService.document.createElement('script');
    script.innerHTML = this.buildHeadScript();
    this.scriptService.appendScriptToHead(script);
  }

  private buildHeadScript(): string {
    return `
      (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "${this.gtm}");
    `;
  }

  private appendBodyScript(): void {
    if (!this.documentService.document) return;

    const noscript = this.documentService.document.createElement('noscript');
    noscript.innerHTML = this.buildBodyScript();
    this.scriptService.prependScriptToBody(noscript);
  }

  private buildBodyScript(): string {
    return `
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=${this.gtm}"
        height="0"
        width="0"
        style="display: none; visibility: hidden">
      </iframe>
    `;
  }
}
