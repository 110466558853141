import { Injectable } from '@angular/core';
import { IFAQ } from 'src/app/features/faq/interfaces/IFaq';
import { FaqService } from 'src/app/features/faq/services/faq.service';

@Injectable()
export class FaqCarouselFacade {
  constructor(private faqService: FaqService) {}

  public getCarouselFaq(): IFAQ[] {
    const faqs = this.faqService.getFaqs();
    const indexes = this.getRandomIndexes(faqs.length, 5);

    return indexes.map((i) => faqs[i]);
  }

  private getRandomIndexes(max: number, count: number = 3): number[] {
    const numbers: number[] = [];

    while (numbers.length < count) {
      const random = Math.floor(Math.random() * max);
      if (!numbers.includes(random)) {
        numbers.push(random);
      }
    }

    return numbers;
  }
}
