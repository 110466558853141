/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontPagesModule } from './pages/front-pages/front-pages.module';
import { PartnersPagesModule } from './pages/partners-pages/partners-pages.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => FrontPagesModule,
  },
  {
    path: 'partners',
    loadChildren: () => PartnersPagesModule,
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
