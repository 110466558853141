export enum SnackbarType {
  error,
  success,
}

export interface ISnackbarConfig {
  message: string;
  type?: SnackbarType;
  action?: string;
}
