import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements  AfterViewInit {
  title = 'Life After Me';

  constructor(private router: Router, private viewportScroller: ViewportScroller) {}

  public ngAfterViewInit(): void {
    this.navigate();
  }

  private navigate(): void {
    this.router.events.pipe(filter((e) => e instanceof Scroll)).subscribe((e: any) => {
      if (e.position) {
        // backward navigation
        this.viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor(e.anchor);
        }, 10);
      } else {
        // forward navigation
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
