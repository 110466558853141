import { NgModule } from '@angular/core';
import { PlansService } from './services/plans.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [PlansService],
})
export class PlansModule {}
