import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-front-pages-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() public logo?: string;

  @Input() public isMobileView = true;

  @Output() public mainMenuClick = new EventEmitter();

  @Output() public closeMenu = new EventEmitter();

  public onIconClick(): void {
    this.mainMenuClick.emit();
  }

  public onCloseMenu(): void {
    this.closeMenu.emit();
  }
}
