import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import discountQuery from '../gql/discount.gql';

@Injectable()
export class DiscountGQLService {
  constructor(private apollo: Apollo) {}

  public applyDiscount(
    code: string,
  ): Observable<FetchResult<any>> {
    return this.apollo.query<any>({
      query: discountQuery,
      variables: {
        code,
      },
      errorPolicy: 'all',
    });
  }
}
