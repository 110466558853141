import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './features/shared/shared.module';
import { CoreModule } from './features/core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { PlansModule } from './features/plans/plans.module';
import { FaqModule } from './features/faq/faq.module';
import { GraphQLModule } from './features/graphql/graphql.module';
import { SnackbarModule } from './features/snackbar/snackbar.module';
import { GoogleTagManagerModule } from './features/goole-tag-manager/google-tag-manager.module';
import { CookiesModule } from './features/cookies/cookies.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'lamApp' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    CoreModule,
    GoogleTagManagerModule,
    GraphQLModule,
    SharedModule,
    PlansModule,
    FaqModule,
    SnackbarModule,
    CookiesModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
