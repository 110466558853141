import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { PartnersGQLService } from './services/partners.gql.service';
import { DiscountGQLService } from './services/discount.gql.service';

@Injectable()
export class PartnersFormFacade {
  constructor(private partnersService: PartnersGQLService, private discountService: DiscountGQLService) {}

  public partnersSubmit(
    languageCode: string,
    firstName: string,
    lastName: string,
    email: string,
    contact: string,
    discountCode: string,
    userOrigin: string,
    province: string,
  ): Observable<FetchResult<any>> {
    return this.partnersService.partnersSubmit(
      languageCode,
      firstName,
      lastName,
      email,
      contact,
      discountCode,
      userOrigin,
      province,
    );
  }

  public applyDiscount(
    code: string,
  ): Observable<FetchResult<any>> {
    return this.discountService.applyDiscount(
      code,      
    );
  }
}
