import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shared-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit, OnDestroy {
  @Input() variant = 'default';

  public logoImage = 'assets/images/logo/logo--xs.webp';

  private breakpointSubscription: Subscription | null = null;

  constructor(private breakpointObserver: BreakpointObserver) {}

  public ngOnInit(): void {
    this.initBreakpoints();
  }

  public ngOnDestroy(): void {
    this.breakpointSubscription?.unsubscribe();
  }

  private initBreakpoints(): void {
    this.breakpointSubscription = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result: BreakpointState) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.logoImage = 'assets/images/logo/logo--xs.webp';
        } else {
          this.logoImage = 'assets/images/logo/logo--sm.webp';
        }
      });
  }
}
