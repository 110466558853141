import { ILocale } from '../interfaces/ILocale';

export const LOCALES: ILocale[] = [
  {
    locale: 'en',
    icon: 'gb',
  },
  {
    locale: 'nl',
    icon: 'nl',
  },
  {
    locale: 'ca',
    icon: 'ca',
  },
];
