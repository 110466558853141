import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable()
export class SignUpFormService {
  public buildSignUpForm() {
    return new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  public isValid(formGroup: FormGroup) {
    if (!formGroup.valid) return false;
    return true;
  }
}
