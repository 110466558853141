import { NgModule } from '@angular/core';
import { GoogleTagManagerComponent } from './google-tag-manager.component';

@NgModule({
  declarations: [GoogleTagManagerComponent],
  imports: [],
  exports: [GoogleTagManagerComponent],
  providers: [],
})
export class GoogleTagManagerModule {}
