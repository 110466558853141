import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISnackbarConfig, SnackbarType } from '../interfaces/ISnackbarConfig';

@Injectable()
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  public open(config: ISnackbarConfig): void {
    const classes = ['snackbar'];
    if (config.type === SnackbarType.error) {
      classes.push('snackbar--error');
    } else {
      classes.push('snackbar--success');
    }

    this.snackbar.open(config.message, config.action, {
      panelClass: classes,
    });
  }
}
