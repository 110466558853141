<div class="footer-menu">
  <ul>
    <li *ngFor="let item of menuItems">
      <a *ngIf="item.type === linkType.INTERNAL" [routerLink]="item.routerLink">{{ item.text }}</a>
      <a *ngIf="item.type === linkType.EXTERNAL" [href]="item.routerLink" target="_blank">{{
        item.text
      }}</a>
      <a *ngIf="item.type === linkType.FILE" [href]="item.routerLink" target="_blank">{{
        item.text
      }}</a>
    </li>
  </ul>
</div>
