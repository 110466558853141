import { InjectionToken, NgModule } from '@angular/core';

import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { BrowserTransferStateModule, makeStateKey, TransferState } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

const APOLLO_CACHE = new InjectionToken<InMemoryCache>('apollo-cache');
const STATE_KEY = makeStateKey<any>('apollo.state');

const BACKEND_URI = environment.backendUri;

@NgModule({
  declarations: [],
  imports: [BrowserTransferStateModule],
  exports: [],
  providers: [
    {
      provide: APOLLO_CACHE,
      useValue: new InMemoryCache(),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, cache: InMemoryCache, transferState: TransferState): any {
        const isBrowser = transferState.hasKey<any>(STATE_KEY);

        if (isBrowser) {
          const state = transferState.get<any>(STATE_KEY, null);
          cache.restore(state);
        } else {
          transferState.onSerialize(STATE_KEY, () => cache.extract());
          // Reset cache after extraction to avoid sharing between requests
          cache.reset();
        }

        return {
          link: httpLink.create({ uri: `${BACKEND_URI}/graphql` }),
          cache,
          ssrForceFetchDelay: 100,
          ssrMode: true,
        };
      },
      deps: [HttpLink, APOLLO_CACHE, TransferState],
    },
  ],
})
export class GraphQLModule {}
