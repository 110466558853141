export enum TextType {
  TITLE = 0,
  SUBTITLE = 1,
  PARAGRAPH = 2,
  LIST = 3,
}

export interface IText {
  text?: string;
  type: TextType;
  list?: string[];
}
