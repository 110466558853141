import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonTypes } from 'src/app/features/core/enums/ButtonTypes';

@Component({
  selector: 'app-shared-lam-button',
  templateUrl: './lam-button.html',
  styleUrls: ['./lam-button.scss'],
})
export class LAMButtonComponent {
  @Input() public buttonText? = 'button';

  @Input() public backgroundColor? = '#fff';

  @Input() public color? = '#000';

  @Input() public link: string | undefined;

  @Input() public buttonType: ButtonTypes = ButtonTypes.SIMPLE_BUTTON;

  @Input() public fragment: string | undefined;

  @Output() public buttonClick = new EventEmitter();

  public buttonTypes = ButtonTypes;

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
