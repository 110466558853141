import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { NLOnlyGuardService } from './guards/nl-only-guard.service';
import { LanguageComponent } from './language.component';
import { LanguageFacade } from './language.facade';
import { LanguageState } from './state/language.state';

@NgModule({
  declarations: [LanguageComponent],
  imports: [CommonModule, MaterialModule],
  exports: [LanguageComponent],
  providers: [LanguageFacade, LanguageState, NLOnlyGuardService],
})
export class LanguageModule {}
