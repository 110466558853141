<mat-action-list class="language">
  <ng-container *ngFor="let loc of locales; let i = index">
    <a
      [href]="'/' + loc.locale + currentRoute"
      class="language__item"
      mat-list-item
      (click)="onLanguageUpdate($event, loc.locale)"
    >
      {{ loc.locale }}
    </a>
    <ng-container *ngIf="i !== locales.length - 1"> | </ng-container>
  </ng-container>
</mat-action-list>
