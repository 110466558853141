import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import signUpSubmitMutation from '../gql/signup.gql';

@Injectable()
export class SignUpGQLService {
  constructor(private apollo: Apollo) {}

  public signupSubmit(
    firstName: string,
    lastName: string,
    email: string,
    languageCode: string,
    userOrigin: string,
  ): Observable<FetchResult<any>> {
    return this.apollo.mutate<any>({
      mutation: signUpSubmitMutation,
      variables: {
        firstName,
        lastName,
        email,
        languageCode,    
        userOrigin,  
      },
      errorPolicy: 'all',
    });
  }
}
