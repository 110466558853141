import { TextType } from '../../core/interfaces/IText';
import { IFAQ } from '../interfaces/IFaq';
import { environment } from 'src/environments/environment';

export const proFAQS: IFAQ[] = [
  {
    question: $localize`Why Life After Me?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`The key principle behind the Life After Me-service is ‘Love for your friends & family, Care for their future and Respect towards your own legacy’.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Helping you to get organized`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Our platform offers you a secure online storage with a range of categories and folders to categorize your most important items, and anything else you would like to safely store.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can easily upload or ‘drag-and-drop’ any type of file, pictures or videos, into the folder where you want it. With a premium account you can then assign the trusted appointee or trusted appointees that you want to share it with. And thanks to you preparing and organizing ahead of time it will be a tremendous help in case others need to figure out how you arranged your affairs.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Helping the helpers, your superstars`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Most of us can use a little help from a friend once in while! In case you struggle on the computer and everything being ‘digital’ these days; reach out to family members, or friends who are digitally savvy and can help you get started; from setting up your account, profile, scan and upload documents for you.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`The helpers can even become your trusted appointee, the superstars in your life who are there to help and support. We are happy to help you and them with our services. Please contact the team at ${environment.supportEmail}`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Your virtual command and control center`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Can you imagine what a relief it would be when you have all your most important administrative affairs nicely organized, structured in one central place and being able to control it all? Our platform is designed specially to do just that. Hassle free setting it up, and once established you can easily change, add or delete documents and files. You are in control.`,
      },
    ],
  },
  {
    question: $localize` What is Life After Me?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Life After Me is an easy to use, secure, online service platform where individuals and families can store copies of all of their important documents.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`This makes it easy to retrieve the information in the event of passing / disability or critical illness of the account holder or other circumstances such as theft, fire, flooding or an earthquake, or any other kind of incident that can cause the loss of your most important and valuable documents.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Can you imagine what a hassle it would be to retrieve copies and/or renew all of these documents?`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`With a Life After Me account, you will have copies of all documents you desire so that, although you may lose the originals, you can still take the copy and go to the relevant authority or company with all of the details to obtain a new document. You will know the issuing authority and the certificate number.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Life After Me also has a process to allow trusted appointees (family members, trusted friends or official trustees) to access all of this important information in the unfortunate event of the death of the account holder.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`When you have a Life After Me account the trusted appointee/trusted appointees will have access to all of the documents that they need to assist them in the process of recovering your assets, legacy and memories. Also, any important contact details of your lawyer and/or other essential people such as an executor.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Life After Me helps you to get organized for your personal life and for those that you leave behind when you pass on.`,
      },
    ],
  },
  {
    question: $localize`Is Life After Me secure?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Yes, completely secure. At Life After Me, it is our number one priority to ensure a maximum level of security and confidentiality for your data. Even we cannot access or view data in your Life After Me account.`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`We use a minimum of 8 character passwords with a mix of numbers, upper case and lower case and a special character. This is very difficult to crack.`,
          $localize`We use Two Factor Authentication via e-mail`,
          $localize`We have an Extended Validation SSL Certificate – See the green lock and green https:// in the URL`,
          $localize`Azure Cloud; Meta-data and data are stored in different physical locations each with 128bit encryption`,
          $localize`We cannot access or read your documents at any point`,
          $localize`Bring in the hackers; we regularly employ ethical hackers to perform so called penetration testing, aka, people trying to hack the Life After Me platform.`,
          $localize`Exit strategy: You have the option to completely erase and delete your Life After Me account at any time.`,
        ],
      },
    ],
  },
  {
    question: $localize`How does Life After Me work?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can start with a free trial account where you choose the plan that is best for you. You can always upgrade or downgrade. Once your information has been processed you can login to Life After Me and start uploading copies of documents, images and video into your Life After Me account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can also add a trusted appointee/trusted appointees such as family member(s) and/or beneficiaries who can access your wishes after you passed away.`,
      },
    ],
  },
  {
    question: $localize`Does Life After Me keep my credit card details?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`No, we never store or hold your credit card details. At Life After Me we use the secure online payment gateway Stripe.`,
      },
    ],
  },
  {
    question: $localize`Can I share my Life After Me Account with my partner or friend?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Well it is up to you to decide. In short, yes you can if you give him/her your login and password. However, we do not recommend that you do this.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Dual access and storing multiple individual’s personal documents can end up being messy and difficult to manage and they would also have access to personal messages from you to other family members. Life After Me accounts are primarily meant to be for individuals.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Also, the Two Factor Authentication with an OTP will require the necessary logistics.`,
      },
    ],
  },
  {
    question: $localize`How do I navigate in my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`After logging in to Life After Me, you are taken to your dashboard that contains all the essential information to your account and access to all of the various sections of Life After Me.`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Profile: Your personal profile, update your password`,
          $localize`My Trusted Appointees: The access you set up for one or a few trusted family member(s), friends.`,
          $localize`Manage Your Life After Me: Manage all of your legacy, assets and memories in one location, stored conveniently and easy to find`,
          $localize`Manage my Plan: Upgrade, downgrade and/or update your financial information`,
          $localize`Life After Me Overview: A quick overview of your legacy, assets and memories`,
          $localize`Notifications: Latest information about your account`,
          $localize`Help Center: Access to Frequently Asked Questions, reach out to us or even suggest a feature you would like to have`,
          $localize`Support: Just in case you need help from the Life After Me team.`,
        ],
      },
    ],
  },
  {
    question: $localize`Is Life After Me device agnostic, mobile friendly?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Yes. Life After Me can be used with your mobile devices such as mobile phones and tablets. You can manage and upload documents, videos and images from your device straight into your Life After Me account.`,
      },
    ],
  },
  {
    question: $localize`What should I store on my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`The primary reason for having a Life After Me account is to organize your affairs just in case something happens to you. You do this by storing copies of all of those really important documents, and make sure you have at least 1 trusted appointee to share it with. Here are some examples of what you may wish to store in your account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Contacts`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Your Lawyers contact details`,
          $localize`The contact details of the Executors of your Will`,
          $localize`Your Accountant’s details`,
          $localize`Contact details of family members`,
          $localize`Contact details of relatives`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Documents`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Birth Certificates`,
          $localize`Marriage Certificates`,
          $localize`Education Certificates`,
          $localize`Social Insurance Documents`,
          $localize`Medical records and receipts`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Wills`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Living Wills`,
          $localize`Power of attorney`,
          $localize`Beneficiaries of your estate`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Funeral Arrangements`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Preferred location`,
          $localize`Preferred songs`,
          $localize`Preferred setting`,
          $localize`Preferred arrangements overall (mood, happening, etc.)`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Scans of ID Cards`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Scan of Driving License`,
          $localize`Passport Copies`,
          $localize`Credit Card Scans`,
          $localize`List of Bank Account Details`,
          $localize`Mortgage Documents`,
          $localize`Title Deeds`,
          $localize`Loan Documents`,
          $localize`Life Insurance Policies`,
          $localize`Car insurance`,
          $localize`Home insurance Documents`,
          $localize`Scans of Insured items receipts and objects`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Assets`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Pension Documents`,
          $localize`Investment Documents`,
          $localize`Media Files`,
          $localize`Meaningful family images`,
          $localize`Audio files (special songs or messages)`,
          $localize`Video files (family videos or messages to loved ones)`,
        ],
      },
    ],
  },
  {
    question: $localize`What is the General Data Protection Regulation (GDPR)`,
    answer: [
      {
        type: TextType.TITLE,
        text: $localize`General Data Protection Regulation`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`The aim of the General Data Protection Regulation (GDPR) is to protect all EU citizens from privacy and data breaches in an increasingly data-driven world that is vastly different from the time in which the 1995 directive was established. Although the key principles of data privacy still hold true to the previous directive, many changes have been proposed to the regulatory policies; the key points of the GDPR as well as information on the impacts it will have on business can be found below.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`The enforcement date of the GDPR started on the 25th of May 2018 and Life After Me Ltd will be in full compliance of this directive.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Data Subject Rights`,
      },
      {
        type: TextType.SUBTITLE,
        text: $localize`Breach Notification`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Under the GDPR, breach notification will become mandatory in all member states where a data breach is likely to “result in a risk for the rights and freedoms of individuals”. This must be done within 72 hours of first having become aware of the breach. Data processors will also be required to notify their customers, the controllers, “without undue delay” after first becoming aware of a data breach.`,
      },
      {
        type: TextType.SUBTITLE,
        text: $localize`Right to Access`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Part of the expanded rights of data subjects outlined by the GDPR is the right for data subjects to obtain from the data controller confirmation as to whether or not personal data concerning them is being processed, where and for what purpose. Further, the controller shall provide a copy of the personal data, free of charge, in an electronic format. This change is a dramatic shift to data transparency and empowerment of data subjects.`,
      },
      {
        type: TextType.SUBTITLE,
        text: $localize`Right to be Forgotten`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Also known as Data Erasure, the right to be forgotten entitles the data subject to have the data controller erase his/her personal data, cease further dissemination of the data, and potentially have third parties halt processing of the data. The conditions for erasure, as outlined in article 17, include the data no longer being relevant to original purposes for processing, or a data subjects withdrawing consent. It should also be noted that this right requires controllers to compare the subjects’ rights to “the public interest in the availability of the data” when considering such requests.`,
      },
    ],
  },
  {
    question: $localize`What is Two Factor Authentication?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Two Factor Authentication, also known as 2FA, is an extra layer of security that requires not only a password and username but also something that only that user has on them, i.e. a piece of information only they should know or have immediately to hand – such as a physical token.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`With standard security procedures (especially online) only requiring a simple username and password it has become increasingly easy for criminals (either in organised gangs or working alone) to gain access to a user’s private data such as personal and financial details and then use that information to commit fraudulent acts, generally of a financial nature.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`At Life After Me we have a Two Factor Authentication system that is linked to your Email address.`,
      },
    ],
  },
  {
    question: $localize`How does Life After Me secure my data?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`The security of your data is our Number 1 priority and we implement a wide range of security measures to protect it.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`The Life After Me platform is proudly developed by a Dutch internet company and we use state-of-the-art security applications and measures to serve you best;`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Sendgrid (<a href="https://sendgrid.com" target="_blank">https://sendgrid.com</a>) – for email sending Sengrid is a Denver, Colorado based company owned by Twilio (<a href="https://twilio.com" target="_blank">https://twilio.com</a>) and is one of the world’s leading platforms for transactional and marketing email. Sendgrid processes tens of billions of emails per month. This way we want to make sure that our emails reach your inbox.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Microsoft Azure (<a href="https://azure.microsoft.com/" target="_blank">https://azure.microsoft.com</a>) – for hosting your files and data`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Microsoft is one of the world’s leading software companies and Life After Me hosts the services on Microsoft’s Azure cloud servers. We chose Azure’s cloud servers based in Europe. Microsoft Azure uses the highest industry security standards and enables us to keep your files and other data safe.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`By using Stripe and Sendgrid we make sure that we transact your payments and emails in the best possible way and your data is stored safely on one of the world’s most trusted servers at Microsoft Azure.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Password Protection:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We are all familiar with passwords as they are required for all accounts that you create on your computers and on the Internet. At Life After Me we require that you provide at least an 8-character password comprising numbers, upper case, lower case and special characters such as an exclamation mark. See our FAQ’s on Creating Strong Passwords and Pass Phrases.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Two Factor Authentication:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Also known as 2FA, Two Factor Authentication is an optional security level that requires you to enter a unique code, in addition to your password, which is created on your mobile phone when you log in to your account.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Secure SSL:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We have a Extended Validation SSL Certificate – See the green lock and green https:// in the URL. SSL (Secure Sockets Layer) is the standard security technology for establishing an encrypted link between a web server and a browser. This link ensures that all data passed between the web server and browsers remain private and integral.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Encryption:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We use end-to-end encryption to secure your data while it is in transport and while it is stored. Your password is used to create an “Encryption Key” and all of your documents are encrypted with this key. This means that your documents are unreadable without the key so that even we, at Life After Me, cannot read the documents that you store with us.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Penetration Testing:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We regularly employ penetration testing services to try to hack Life After Me both externally and internally.`,
      },
    ],
  },
  {
    question: $localize`How do I log-in?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`From the home page click on the displayed Login link. Assuming that you have already created your Life After Me account you simply need to enter your email address and password and you can login to your account.`,
      },
    ],
  },
  {
    question: $localize`How do I sign up/create an account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Creating an account at Life After Me is straight forward.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`From the home page, click on the displayed Log-in link. You will see the text “Sign up”`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Fill in the form with the requested information,`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Supply your details`,
          $localize`A valid email address`,
          $localize`Telephone number with proper country code`,
          $localize`You will receive an OTP code on your email to secure you are a real person and holder of a valid email address`,
          $localize`Tick the Terms & Conditions box`,
        ],
      },
      {
        type: TextType.TITLE,
        text: $localize`Payment`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You will now be presented with a number of payment options. Feel free to play with the options, nothing will happen until you click on “Next”`,
      },
      {
        type: TextType.LIST,
        list: [$localize`Monthly`, $localize`Yearly (Save up to 25%)`],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Most people select the yearly option. This means that you will be invoiced annually using the credit card that you will present at the payment gateway.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Auto renew means that at the end of the subscription period we will automatically renew your account.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Invoice and Payment`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You will now be able to check your invoice and then click on “Make a payment”.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Payment Gateway Stripe`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You are then taken to the Stripe payment gateway where you should complete the form with your address and card details and click the Payment button. We will only charge you after your trail period has expired. You may cancel at any time without incurring any charges or penalties.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`At Stripe you can pay with major credit cards like MasterCard and Visa credit card. In Holland Stripe also accepts IDeal`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Please note: As an extra security feature we do not store your credit card details at Life After Me. It is all managed by the Stripe Payment Gateway.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Validate your email`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Once you have completed the financial information, you will be sent an email in order to verify your email address.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`After you have verified your email address using the OTP we had sent, you will need to create a password with a minimum of 8 characters with a mix of upper case, lower case, numeric and one special character (! $ % etc.), to be able to login to Life After Me.`,
      },
    ],
  },
  {
    question: $localize`How do I benefit most from my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Some suggestions that you may take to make the most of your account;`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Your Profile`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Once you have selected your plan and have logged in, the first thing to do is to complete your profile information. This is just the usual basic information about you.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Set up your trusted appointee/trusted appointees – people who you wish to have access to your account in the event of your death. This would usually be a close family member, friend or trustee and is one of the first points of contacts in the event of your death.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You will find the “Add a Trusted Appointee” option on the side bar.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Set up your administrative folders`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Before you start dropping documents into your Account take a moment to think how you want to store everything that you are going to keep there. We already conveniently set up a broad range of key folders`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Suggestions to upload`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Certificates (Weddings, birth)`,
          $localize`Official Documents (ID, Social Insurance, Passport etc.)`,
          $localize`Financial: Bank details, Stock and Shares, Credit Card copies, Loans`,
          $localize`Insurance: Home, Life, Car, Injury, Medical`,
          $localize`House and Home: Mortgage, House Insurance`,
          $localize`Memories: Pictures, videos and special images`,
          $localize`In the event of my death: Wills, last wishes, funeral specifications and arrangements, Power of Attorney`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You may either drag and drop your files into the Master Account or you can upload them with the upload button.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Set up the folders for your trusted appointee`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`As you uploaded the documents, you can now easily allocate which trusted appointee/trusted appointees will be able to get access to these documents, in case you pass away (Available in premium plan only).`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Finally, you will need to tell all of your Trusted Appointees who you wish to have access to the account that you have created a Life After Me Account. Notify them and ask them to create and store their password to gain access the account.`,
      },
    ],
  },
  {
    question: $localize`Why do I need to assign Trusted Appointees?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`When you set up your Trusted Appointees you are essentially creating a group of people who you want to give some sort of Digital Inheritance to. This can be to allow the selected trusted appointee to manage some or all of your stored data. Also, you might want to leave them a personal parting message.`,
      },
    ],
  },
  {
    question: $localize`How do I delete my account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Should you wish to delete your account entirely this can be done through your profile.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Click on “Profile” on the side bar and then click on the edit icon.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`If you scroll down to the bottom you will see a button that allows you to delete your account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Please be aware that this will remove all files from the system and you will not be able to login to the system again. We will cease billing you at the end of the current annual billing cycle.`,
      },
    ],
  },
  {
    question: $localize`I forgot my username`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Your login username will be the email address that you registered with.`,
      },
    ],
  },
  {
    question: $localize`I forgot my password`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Go to the “Login” page and click on the “Forgot password” link.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We will send a password reset to your email address.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Click on the button in the email and it will take you to a page that will allow you to create a new password.`,
      },
    ],
  },
  {
    question: $localize`What file types can I use for my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can store pretty much any kind of file into your account. Word Documents, Excel Files, PDF Documents, Image files and video files. Just be aware of the size of your data storage in your subscription.`,
      },
    ],
  },
  {
    question: $localize`How do I delete my documents?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Once logged in, go to your account and find the document that you want to delete, aka look in one of your folders where you have stored the document.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Click on the document menu and then click “delete”.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`If you wish to delete multiple documents at the same time, you can select them by checking the tick box of the document and then clicking the delete button on the top of the files.`,
      },
    ],
  },
  {
    question: $localize`How do I know my account is full?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`In the side bar on the left, you will see the actual usage of your account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`When you are reaching your maximum storage capacity, the system will inform you. You will have an option to either upgrade or delete files.`,
      },
    ],
  },
  {
    question: $localize`Who can see my Password?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Like everything else at Life After Me, only you can see the passwords that you store. What you would probably do, if you wish, is to assign access to the passwords to one of your trusted appointees so that they can close down your accounts when the Life After Me account is unlocked.`,
      },
    ],
  },
  {
    question: $localize`What happens when I die, with the Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`In the event of your death one (or more) of your assigned trusted appointees will have to notify us about this.They do this by logging in to their Life After Me account with using their login details and declare you deceased.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`As a security feature, you, and the other trusted appointees will receive a notification of your passing. With the set time slot (default is 2 hours), the trusted appointee will get access to your account and its data.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`In case a trusted appointee pulls a prank, or wrongfully tries to gain access you can immediately revoke their account and access via the verification email we have sent when they had marked you as deceased. Please be aware that we as Life After Me cannot take any responsibility for the behavior of your assigned/chosen trusted appointees. (see the general terms and conditions and our disclaimer).`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`However, we consider that you choose wisely and have a trusted trusted appointee, who in the event of your passing can take care of closing down your accounts, such as social media, and also take care of the administrative wrap up. In case you opt for keeping the account open for legacy and memories purposes your trusted appointee can prolong the account. Otherwise it will be automatically closed after 1 month and all files will be deleted permanently.`,
      },
    ],
  },
];

export const caFAQS: IFAQ[] = [
  {
    question: $localize`Why Life After Me?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`The key principle behind the Life After Me-service is ‘Love for your friends & family, Care for their future and Respect towards your own legacy’.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Helping you to get organized`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Our platform offers you a secure online storage with a range of categories and folders to categorize your most important items, and anything else you would like to safely store.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can easily upload or ‘drag-and-drop’ any type of file, pictures or videos, into the folder where you want it. With a premium account you can then assign the trusted appointee or trusted appointees that you want to share it with. And thanks to you preparing and organizing ahead of time it will be a tremendous help in case others need to figure out how you arranged your affairs.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Helping the helpers, your superstars`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Most of us can use a little help from a friend once in while! In case you struggle on the computer and everything being ‘digital’ these days; reach out to family members, or friends who are digitally savvy and can help you get started; from setting up your account, profile, scan and upload documents for you.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`The helpers can even become your trusted appointee, the superstars in your life who are there to help and support. We are happy to help you and them with our services. Please contact the team at ${environment.supportEmail}`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Your virtual command and control center`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Can you imagine what a relief it would be when you have all your most important administrative affairs nicely organized, structured in one central place and being able to control it all? Our platform is designed specially to do just that. Hassle free setting it up, and once established you can easily change, add or delete documents and files. You are in control.`,
      },
    ],
  },
  {
    question: $localize` What is Life After Me?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Life After Me is an easy to use, secure, online service platform where individuals and families can store copies of all of their important documents.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`This makes it easy to retrieve the information in the event of passing / disability or critical illness of the account holder or other circumstances such as theft, fire, flooding or an earthquake, or any other kind of incident that can cause the loss of your most important and valuable documents.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Can you imagine what a hassle it would be to retrieve copies and/or renew all of these documents?`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`With a Life After Me account, you will have copies of all documents you desire so that, although you may lose the originals, you can still take the copy and go to the relevant authority or company with all of the details to obtain a new document. You will know the issuing authority and the certificate number.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Life After Me also has a process to allow trusted appointees (family members, trusted friends or official trustees) to access all of this important information in the unfortunate event of the death of the account holder.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`When you have a Life After Me account the trusted appointee/trusted appointees will have access to all of the documents that they need to assist them in the process of recovering your assets, legacy and memories. Also, any important contact details of your lawyer and/or other essential people such as an executor.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Life After Me helps you to get organized for your personal life and for those that you leave behind when you pass on.`,
      },
    ],
  },
  {
    question: $localize`Is Life After Me secure?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Yes, completely secure. At Life After Me, it is our number one priority to ensure a maximum level of security and confidentiality for your data. Even we cannot access or view data in your Life After Me account.`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`We use a minimum of 8 character passwords with a mix of numbers, upper case and lower case and a special character. This is very difficult to crack.`,
          $localize`We use Two Factor Authentication via e-mail`,
          $localize`We have an Extended Validation SSL Certificate – See the green lock and green https:// in the URL`,
          $localize`Azure Cloud; Meta-data and data are stored in different physical locations each with 128bit encryption`,
          $localize`We cannot access or read your documents at any point`,
          $localize`Bring in the hackers; we regularly employ ethical hackers to perform so called penetration testing, aka, people trying to hack the Life After Me platform.`,
          $localize`Exit strategy: You have the option to completely erase and delete your Life After Me account at any time.`,
        ],
      },
    ],
  },
  {
    question: $localize`How does Life After Me work?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can start with a free trial account where you choose the plan that is best for you. You can always upgrade or downgrade. Once your information has been processed you can login to Life After Me and start uploading copies of documents, images and video into your Life After Me account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can also add a trusted appointee/trusted appointees such as family member(s) and/or beneficiaries who can access your wishes after you passed away.`,
      },
    ],
  },
  {
    question: $localize`Does Life After Me keep my credit card details?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`No, we never store or hold your credit card details. At Life After Me we use the secure online payment gateway Stripe.`,
      },
    ],
  },
  {
    question: $localize`Can I share my Life After Me Account with my partner or friend?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Well it is up to you to decide. In short, yes you can if you give him/her your login and password. However, we do not recommend that you do this.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Dual access and storing multiple individual’s personal documents can end up being messy and difficult to manage and they would also have access to personal messages from you to other family members. Life After Me accounts are primarily meant to be for individuals.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Also, the Two Factor Authentication with an OTP will require the necessary logistics.`,
      },
    ],
  },
  {
    question: $localize`How do I navigate in my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`After logging in to Life After Me, you are taken to your dashboard that contains all the essential information to your account and access to all of the various sections of Life After Me.`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Profile: Your personal profile, update your password`,
          $localize`My Trusted Appointees: The access you set up for one or a few trusted family member(s), friends.`,
          $localize`Manage Your Life After Me: Manage all of your legacy, assets and memories in one location, stored conveniently and easy to find`,
          $localize`Manage my Plan: Upgrade, downgrade and/or update your financial information`,
          $localize`Life After Me Overview: A quick overview of your legacy, assets and memories`,
          $localize`Notifications: Latest information about your account`,
          $localize`Help Center: Access to Frequently Asked Questions, reach out to us or even suggest a feature you would like to have`,
          $localize`Support: Just in case you need help from the Life After Me team.`,
        ],
      },
    ],
  },
  {
    question: $localize`Is Life After Me device agnostic, mobile friendly?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Yes. Life After Me can be used with your mobile devices such as mobile phones and tablets. You can manage and upload documents, videos and images from your device straight into your Life After Me account.`,
      },
    ],
  },
  {
    question: $localize`What should I store on my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`The primary reason for having a Life After Me account is to organize your affairs just in case something happens to you. You do this by storing copies of all of those really important documents, and make sure you have at least 1 trusted appointee to share it with. Here are some examples of what you may wish to store in your account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Contacts`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Your Lawyers contact details`,
          $localize`The contact details of the Executors of your Will`,
          $localize`Your Accountant’s details`,
          $localize`Contact details of family members`,
          $localize`Contact details of relatives`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Documents`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Birth Certificates`,
          $localize`Marriage Certificates`,
          $localize`Education Certificates`,
          $localize`Social Insurance Documents`,
          $localize`Medical records and receipts`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Wills`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Living Wills`,
          $localize`Power of attorney`,
          $localize`Beneficiaries of your estate`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Funeral Arrangements`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Preferred location`,
          $localize`Preferred songs`,
          $localize`Preferred setting`,
          $localize`Preferred arrangements overall (mood, happening, etc.)`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Scans of ID Cards`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Scan of Driving License`,
          $localize`Passport Copies`,
          $localize`Credit Card Scans`,
          $localize`List of Bank Account Details`,
          $localize`Mortgage Documents`,
          $localize`Title Deeds`,
          $localize`Loan Documents`,
          $localize`Life Insurance Policies`,
          $localize`Car insurance`,
          $localize`Home insurance Documents`,
          $localize`Scans of Insured items receipts and objects`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Assets`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Pension Documents`,
          $localize`Investment Documents`,
          $localize`Media Files`,
          $localize`Meaningful family images`,
          $localize`Audio files (special songs or messages)`,
          $localize`Video files (family videos or messages to loved ones)`,
        ],
      },
    ],
  },
  {
    question: `What is Personal Information Protection and Electronic Documents Act(PIPEDA)?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: `The Personal Information Protection and Electronic Documents Act (PIPEDA) is a federal law in Canada that governs the collection, use, and disclosure of personal information by organizations in the course of commercial activities. The law applies to organizations in all sectors, including private sector organizations, non-profit organizations, and federal government organizations that collect, use, or disclose personal information in the course of commercial activities.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: `Compliance with PIPEDA is important for organizations as it helps to protect the privacy rights of individuals and maintain their trust in organizations that collect and use their personal information. Failure to comply with PIPEDA can result in penalties and damage to an organization's reputation.`,
      },
    ],
  },
  {
    question: $localize`What is Two Factor Authentication?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Two Factor Authentication, also known as 2FA, is an extra layer of security that requires not only a password and username but also something that only that user has on them, i.e. a piece of information only they should know or have immediately to hand – such as a physical token.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`With standard security procedures (especially online) only requiring a simple username and password it has become increasingly easy for criminals (either in organised gangs or working alone) to gain access to a user’s private data such as personal and financial details and then use that information to commit fraudulent acts, generally of a financial nature.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`At Life After Me we have a Two Factor Authentication system that is linked to your Email address.`,
      },
    ],
  },
  {
    question: $localize`How does Life After Me secure my data?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`The security of your data is our Number 1 priority and we implement a wide range of security measures to protect it.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`The Life After Me platform is proudly developed by a Dutch internet company and we use state-of-the-art security applications and measures to serve you best;`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Sendgrid (<a href="https://sendgrid.com" target="_blank">https://sendgrid.com</a>) – for email sending Sengrid is a Denver, Colorado based company owned by Twilio (<a href="https://twilio.com" target="_blank">https://twilio.com</a>) and is one of the world’s leading platforms for transactional and marketing email. Sendgrid processes tens of billions of emails per month. This way we want to make sure that our emails reach your inbox.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Microsoft Azure (<a href="https://azure.microsoft.com/" target="_blank">https://azure.microsoft.com</a>) – for hosting your files and data`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Microsoft is one of the world’s leading software companies and Life After Me hosts the services on Microsoft’s Azure cloud servers. We chose Azure’s cloud servers based in Europe. Microsoft Azure uses the highest industry security standards and enables us to keep your files and other data safe.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`By using Stripe and Sendgrid we make sure that we transact your payments and emails in the best possible way and your data is stored safely on one of the world’s most trusted servers at Microsoft Azure.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Password Protection:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We are all familiar with passwords as they are required for all accounts that you create on your computers and on the Internet. At Life After Me we require that you provide at least an 8-character password comprising numbers, upper case, lower case and special characters such as an exclamation mark. See our FAQ’s on Creating Strong Passwords and Pass Phrases.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Two Factor Authentication:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Also known as 2FA, Two Factor Authentication is an optional security level that requires you to enter a unique code, in addition to your password, which is created on your mobile phone when you log in to your account.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Secure SSL:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We have a Extended Validation SSL Certificate – See the green lock and green https:// in the URL. SSL (Secure Sockets Layer) is the standard security technology for establishing an encrypted link between a web server and a browser. This link ensures that all data passed between the web server and browsers remain private and integral.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Encryption:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We use end-to-end encryption to secure your data while it is in transport and while it is stored. Your password is used to create an “Encryption Key” and all of your documents are encrypted with this key. This means that your documents are unreadable without the key so that even we, at Life After Me, cannot read the documents that you store with us.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Penetration Testing:`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We regularly employ penetration testing services to try to hack Life After Me both externally and internally.`,
      },
    ],
  },
  {
    question: $localize`How do I log-in?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`From the home page click on the displayed Login link. Assuming that you have already created your Life After Me account you simply need to enter your email address and password and you can login to your account.`,
      },
    ],
  },
  {
    question: $localize`How do I sign up/create an account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Creating an account at Life After Me is straight forward.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`From the home page, click on the displayed Log-in link. You will see the text “Sign up”`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Fill in the form with the requested information,`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Supply your details`,
          $localize`A valid email address`,
          $localize`Telephone number with proper country code`,
          $localize`You will receive an OTP code on your email to secure you are a real person and holder of a valid email address`,
          $localize`Tick the Terms & Conditions box`,
        ],
      },
      {
        type: TextType.TITLE,
        text: $localize`Payment`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You will now be presented with a number of payment options. Feel free to play with the options, nothing will happen until you click on “Next”`,
      },
      {
        type: TextType.LIST,
        list: [$localize`Monthly`, $localize`Yearly (Save up to 25%)`],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Most people select the yearly option. This means that you will be invoiced annually using the credit card that you will present at the payment gateway.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Auto renew means that at the end of the subscription period we will automatically renew your account.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Invoice and Payment`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You will now be able to check your invoice and then click on “Make a payment”.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Payment Gateway Stripe`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You are then taken to the Stripe payment gateway where you should complete the form with your address and card details and click the Payment button. We will only charge you after your trail period has expired. You may cancel at any time without incurring any charges or penalties.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`At Stripe you can pay with major credit cards like MasterCard and Visa credit card. In Holland Stripe also accepts IDeal`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Please note: As an extra security feature we do not store your credit card details at Life After Me. It is all managed by the Stripe Payment Gateway.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Validate your email`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Once you have completed the financial information, you will be sent an email in order to verify your email address.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`After you have verified your email address using the OTP we had sent, you will need to create a password with a minimum of 8 characters with a mix of upper case, lower case, numeric and one special character (! $ % etc.), to be able to login to Life After Me.`,
      },
    ],
  },
  {
    question: $localize`How do I benefit most from my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Some suggestions that you may take to make the most of your account;`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Your Profile`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Once you have selected your plan and have logged in, the first thing to do is to complete your profile information. This is just the usual basic information about you.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Set up your trusted appointee/trusted appointees – people who you wish to have access to your account in the event of your death. This would usually be a close family member, friend or trustee and is one of the first points of contacts in the event of your death.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You will find the “Add a Trusted Appointee” option on the side bar.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Set up your administrative folders`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Before you start dropping documents into your Account take a moment to think how you want to store everything that you are going to keep there. We already conveniently set up a broad range of key folders`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Suggestions to upload`,
      },
      {
        type: TextType.LIST,
        list: [
          $localize`Certificates (Weddings, birth)`,
          $localize`Official Documents (ID, Social Insurance, Passport etc.)`,
          $localize`Financial: Bank details, Stock and Shares, Credit Card copies, Loans`,
          $localize`Insurance: Home, Life, Car, Injury, Medical`,
          $localize`House and Home: Mortgage, House Insurance`,
          $localize`Memories: Pictures, videos and special images`,
          $localize`In the event of my death: Wills, last wishes, funeral specifications and arrangements, Power of Attorney`,
        ],
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`You may either drag and drop your files into the Master Account or you can upload them with the upload button.`,
      },
      {
        type: TextType.TITLE,
        text: $localize`Set up the folders for your trusted appointee`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`As you uploaded the documents, you can now easily allocate which trusted appointee/trusted appointees will be able to get access to these documents, in case you pass away (Available in premium plan only).`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Finally, you will need to tell all of your Trusted Appointees who you wish to have access to the account that you have created a Life After Me Account. Notify them and ask them to create and store their password to gain access the account.`,
      },
    ],
  },
  {
    question: $localize`Why do I need to assign Trusted Appointees?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`When you set up your Trusted Appointees you are essentially creating a group of people who you want to give some sort of Digital Inheritance to. This can be to allow the selected trusted appointee to manage some or all of your stored data. Also, you might want to leave them a personal parting message.`,
      },
    ],
  },
  {
    question: $localize`How do I delete my account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Should you wish to delete your account entirely this can be done through your profile.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Click on “Profile” on the side bar and then click on the edit icon.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`If you scroll down to the bottom you will see a button that allows you to delete your account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Please be aware that this will remove all files from the system and you will not be able to login to the system again. We will cease billing you at the end of the current annual billing cycle.`,
      },
    ],
  },
  {
    question: $localize`I forgot my username`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Your login username will be the email address that you registered with.`,
      },
    ],
  },
  {
    question: $localize`I forgot my password`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Go to the “Login” page and click on the “Forgot password” link.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`We will send a password reset to your email address.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Click on the button in the email and it will take you to a page that will allow you to create a new password.`,
      },
    ],
  },
  {
    question: $localize`What file types can I use for my Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`You can store pretty much any kind of file into your account. Word Documents, Excel Files, PDF Documents, Image files and video files. Just be aware of the size of your data storage in your subscription.`,
      },
    ],
  },
  {
    question: $localize`How do I delete my documents?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Once logged in, go to your account and find the document that you want to delete, aka look in one of your folders where you have stored the document.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`Click on the document menu and then click “delete”.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`If you wish to delete multiple documents at the same time, you can select them by checking the tick box of the document and then clicking the delete button on the top of the files.`,
      },
    ],
  },
  {
    question: $localize`How do I know my account is full?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`In the side bar on the left, you will see the actual usage of your account.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`When you are reaching your maximum storage capacity, the system will inform you. You will have an option to either upgrade or delete files.`,
      },
    ],
  },
  {
    question: $localize`Who can see my Password?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`Like everything else at Life After Me, only you can see the passwords that you store. What you would probably do, if you wish, is to assign access to the passwords to one of your trusted appointees so that they can close down your accounts when the Life After Me account is unlocked.`,
      },
    ],
  },
  {
    question: $localize`What happens when I die, with the Life After Me account?`,
    answer: [
      {
        type: TextType.PARAGRAPH,
        text: $localize`In the event of your death one (or more) of your assigned trusted appointees will have to notify us about this.They do this by logging in to their Life After Me account with using their login details and declare you deceased.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`As a security feature, you, and the other trusted appointees will receive a notification of your passing. With the set time slot (default is 2 hours), the trusted appointee will get access to your account and its data.`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`In case a trusted appointee pulls a prank, or wrongfully tries to gain access you can immediately revoke their account and access via the verification email we have sent when they had marked you as deceased. Please be aware that we as Life After Me cannot take any responsibility for the behavior of your assigned/chosen trusted appointees. (see the general terms and conditions and our disclaimer).`,
      },
      {
        type: TextType.PARAGRAPH,
        text: $localize`However, we consider that you choose wisely and have a trusted trusted appointee, who in the event of your passing can take care of closing down your accounts, such as social media, and also take care of the administrative wrap up. In case you opt for keeping the account open for legacy and memories purposes your trusted appointee can prolong the account. Otherwise it will be automatically closed after 1 month and all files will be deleted permanently.`,
      },
    ],
  },
];
