import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class WindowService {

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  public get window(): Window | null {
    if (!isPlatformBrowser(this.platformId)) return null;

    return window;
  }
}
