import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { SignUpGQLService } from './services/signup.gql.service';

@Injectable()
export class SignUpSectionFacade {
  constructor(private signUpService: SignUpGQLService) {}

  public signUpSubmit(
    firstName: string,
    lastName: string,
    email: string,
    languageCode: string,
    userOrigin: string,
  ): Observable<FetchResult<any>> {
    return this.signUpService.signupSubmit(firstName, lastName, email, languageCode, userOrigin);
  }
}
