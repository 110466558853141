export const environment = {
  production: false,
  version: '0.0.1',
  backendUri: 'https://lam-pro-backend-dev.azurewebsites.net/graphql',
  appUri: 'https://dev-app.lifeafterme.com',
  proDomain: 'dev.lifeafterme.com',
  proAppUri: 'https://pro.dev-app.lifeafterme.com',
  supportEmail: 'support@lifeafterme.com',
  allowGTM: true,
  region: 'global',
};
