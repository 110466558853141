import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { LanguageFacade } from '../language.facade';

@Injectable()
export class NLOnlyGuardService implements CanActivate {
  constructor(private languageFacade: LanguageFacade, private router: Router) {}

  public canActivate(): boolean | UrlTree {
    const locale = this.languageFacade.getCurrentLocale();

    if (locale !== 'nl') return this.router.parseUrl('/');

    return true;
  }
}
