import { NgModule } from '@angular/core';
import { SharedModule } from '../../features/shared/shared.module';
import { BurgerIconComponent } from './components/burger-icon/burger-icon.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { FrontPagesComponent } from './front-pages.component';
import { FrontPagesRoutingModule } from './front-pages.routing';
import { FooterComponent } from './components/footer/footer.component';
import { FooterSocialsComponent } from './components/footer-socials/footer-socials.component';
import { FooterDetailsComponent } from './components/footer-details/footer-details.component';
import { FooterMenuComponent } from './components/footer-menu/footer-menu.component';
import { FooterCompanyComponent } from './components/footer-company/footer-company.component';

@NgModule({
  declarations: [
    FrontPagesComponent,
    HeaderComponent,
    FooterComponent,
    MainMenuComponent,
    BurgerIconComponent,
    FooterSocialsComponent,
    FooterDetailsComponent,
    FooterMenuComponent,
    FooterCompanyComponent,
  ],
  imports: [SharedModule, FrontPagesRoutingModule],
  exports: [],
  providers: [],
})
export class FrontPagesModule {}
