import { NgModule } from '@angular/core';
import { AppCookieService } from './cookies.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [AppCookieService],
})
export class CookiesModule {}
