import { Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { BusinessGQLService } from './services/business.gql.service';

@Injectable()
export class BusinessSectionFacade {
  constructor(private businessService: BusinessGQLService) {}

  public businessSubmit(
    firstName: string,
    lastName: string,
    email: string,
    organization: string,
    phone: string,    
  ): Observable<FetchResult<any>> {
    const message = '';
    const name = `${firstName} ${lastName}`;
    return this.businessService.businessSubmit(name, email, organization, phone, message);
  }
}
