import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartnersPagesComponent  } from './partners-pages.component';

const routes: Routes = [
  {
    path: '',
    component: PartnersPagesComponent,
    children: [
      {
        path: 'brokerage-consilium',
        loadChildren: () => import('../landing-page-bc/landing-page-bc.module').then((m) => m.LandingPageBcModule),
      },
      {
        path: 'pensioen-masters',
        loadChildren: () => import('../landing-page-pm/landing-page-pm.module').then((m) => m.LandingPagePmModule),
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PartnersPagesRoutingModule {}
