<div
  class="footer"
  [ngStyle]="{
    'background-color': showLandingFooter ? '#e8f2ef' : 'white',
    'margin-top': showLandingFooter ? '-10px' : '0px'
  }"
>
  <app-shared-section-mask
    section="footer"
    [showButton]="!showLandingFooter"
  ></app-shared-section-mask>
  <div class="footer--bottom">
    <div>
      <img
        src="assets/images/partners/BH-Belfius3.png"
        width="250"
        height="120"
        alt="birdhouse logo"
        class="logoBH"
      />
      <app-footer-socials></app-footer-socials>
    </div>
    <div class="footer__grid">
      <app-footer-company class="grid__container"></app-footer-company>
      <app-footer-details class="grid__container"></app-footer-details>
      <app-footer-menu class="grid__container" [menuItems]="mainMenuItems"></app-footer-menu>
      <app-footer-menu class="grid__container" [menuItems]="secondaryMenuItems"></app-footer-menu>
    </div>
  </div>
</div>
