<mat-drawer-container class="front-pages">
  <div class="front-pages__content">
    <header>
      <app-front-pages-header
        (mainMenuClick)="toggleMainMenu()"
        [isMobileView]="isMobileView"
        (closeMenu)="toggleMainMenu(false)"
        [logo]="headerLogo"
      ></app-front-pages-header>
    </header>
    <main>
      <router-outlet></router-outlet>
    </main>
    <footer>
      <app-front-pages-footer
        [mainMenuItems]="mainMenu"
        [secondaryMenuItems]="secondaryMenu"
      ></app-front-pages-footer>
    </footer>
  </div>
  <mat-drawer
    class="front-pages__drawer"
    mode="over"
    position="end"
    [opened]="isMainMenuOpen"
    (openedChange)="toggleMainMenu($event)"
  >
    <app-front-pages-main-menu
      [isMobileView]="isMobileView"
      (closeMenu)="toggleMainMenu(false)"
      [appUri]="appUri"
    ></app-front-pages-main-menu>
  </mat-drawer>
</mat-drawer-container>
