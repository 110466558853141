import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ButtonTypes } from 'src/app/features/core/enums/ButtonTypes';

@Component({
  selector: 'app-shared-section-mask',
  templateUrl: './section-mask.component.html',
  styleUrls: ['./section-mask.component.scss'],
})
export class SectionMaskComponent implements OnInit, OnDestroy {
  @Input() public section = 'hero';

  @Input() public showButton = false;

  @Input() public buttonText? = $localize`Try for free`;

  @Input() public link: string = '/why-life-after-me';

  @Input() public fragment: string = 'plans';

  @Input() public customPrimaryColor = '#fff';

  @Input() public customSecondaryColor = '#cb3718';

  @Input() public buttonType: ButtonTypes = ButtonTypes.INTERNAL_LINK_RELATIVE;

  public maskUrl = '';

  public buttonTypes = ButtonTypes;

  private breakpointsSubscription: Subscription | null = null;

  constructor(private breakpointObserver: BreakpointObserver) {}

  public ngOnInit(): void {
    this.initBreakpoints();
  }

  public ngOnDestroy(): void {
    this.breakpointsSubscription?.unsubscribe();
  }

  private initBreakpoints(): void {
    this.breakpointsSubscription = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result: BreakpointState) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.maskUrl = `/assets/images/sections/mask__${this.section}--xs.webp`;
        } else {
          this.maskUrl = `/assets/images/sections/mask__${this.section}--md.webp`;
        }
      });
  }
}
