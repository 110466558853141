<div class="footer-details">
  <p><i class="fas fa-map-marker-alt"></i><span [innerHTML]="LamAddress"></span></p>
  <p>
    <i class="fab fa-whatsapp"></i
    ><a href="https://wa.me/{{ LamNumber }}" target="_blank">{{ LamNumberDisplay }}</a>
  </p>
  <p>
    <i class="fas fa-map-marker-alt"></i
    ><a i18n href="mailto:{{ LamEmail }}" target="_blank">{{ LamEmail }}</a>
  </p>
  <p i18n *ngIf="!isCaVersion">Chamber of Commerce: KVK 80138365</p>
  <p i18n *ngIf="!isCaVersion">VAT number: NL861564789B01</p>
</div>
