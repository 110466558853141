import { Injectable } from '@angular/core';
import { IFAQ } from '../interfaces/IFaq';
import { caFAQS, proFAQS } from '../utils/faqs';
import { environment } from 'src/environments/environment';

@Injectable()
export class FaqService {

  
  public getFaqs(): IFAQ[] {
    if(environment.region === 'ca') return caFAQS;
    return proFAQS;
  }
}
