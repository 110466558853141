export enum LinkType {
  EXTERNAL,
  INTERNAL,
  FILE,
}

export interface MenuItem {
  routerLink: string;
  text: string;
  type: LinkType;
}
